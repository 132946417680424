import React, { useState } from 'react';
import data from "../../data/index.json";

export default function MyPortfolio() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  const certifications = data.Certificats;
  const itemsPerPage = 3;

  const handlePrev = () => {
    setIsAnimating(true); // Start animation
    setTimeout(() => {
      const isFirstSlide = currentIndex === 0;
      const newIndex = isFirstSlide ? certifications.length - 1 : currentIndex - 1;
      setCurrentIndex(newIndex);
      setIsAnimating(false); // Stop animation
    }, 600); // Duration matches the CSS animation duration
  };

  const handleNext = () => {
    setIsAnimating(true); // Start animation
    setTimeout(() => {
      const isLastSlide = currentIndex === certifications.length - itemsPerPage;
      const newIndex = isLastSlide ? 0 : currentIndex + 1;
      setCurrentIndex(newIndex);
      setIsAnimating(false); // Stop animation
    }, 600); // Duration matches the CSS animation duration
  };

  const displayCertifications = () => {
    const end = currentIndex + itemsPerPage;
    return certifications
      .slice(currentIndex, end)
      .concat(certifications.slice(0, Math.max(0, end - certifications.length)));
  };

  return (
    <section className="portfolio--section" id="MyPortfolio">
      <div className="certifications">
        <div className="certifications--container">
          <h2 className="section--heading">My Certifications</h2>
        </div>
        <div className="slideshow-container">
          <button className="left-arrow" onClick={handlePrev}>
            &lt;
          </button>
          <div className={`certifications-display ${isAnimating ? 'flip' : ''}`}>
            {displayCertifications().map((cert, index) => (
              <div className="certification-card flip-card" key={index}>
                <img
                  src={cert.src}
                  alt={cert.title}
                  className="cert-icon"
                />
                <h3>{cert.title}</h3>
                <p>{cert.description}</p>
              </div>
            ))}
          </div>
          <button className="right-arrow" onClick={handleNext}>
            &gt;
          </button>
        </div>
      </div>
    </section>
  );
}
