export default function AboutMe() {
    return (
        <section id="AboutMe" className="about--section">
            <div className="about--section--img">
                <img src="./img/hero.jpg" alt="About Me" />
            </div>
            <div className="hero--section--content--box about--section--box ">
                <div className="hero--section--content">
                    <p className="section--title">
                        " Nothing will work unless you do "
                    </p>
                    <p className="about--section--description">
                    I'm HAMMANI Mohamed, a Junior Big Data and Data Science Engineer based in Canada, with expertise in data integration, machine learning, and creating insights from complex datasets. 
                    <br/><br/>I’ve worked on projects ranging from building deep learning models to developing dashboards and constructing pipelines.<br/><br/>
                    I hold an engineering degree in Big Data and Data Science from the International University of Rabat and i'm currently pursuing a M.S. in AI and Data Science at Sherbrooke University.
                    <br/><br/>
                    Though my journey has just begun, I strive for what lies beyond the horizon, curious about what life has to offer.<br/>Hence, To truly understand, one must seek mastery, and that’s who I am as a person, goal-driven, eager to learn, and quick to adapt.<br/><br/>
                    Feel free to reach out, let's embark on this journey together.
                    </p>
                </div>
            </div>
        </section>
    )
}